.pageHeader, .intro {
  @apply mb-2xl;
}

.pageHeader h2 {
  @apply my-sm;
}

.intro {
  @apply flex flex-col gap-2xl;
  @apply sm:grid sm:grid-cols-2;
}

.onlyTextAnalytics {
  @apply gap-[var(--spacing-0-25)];
}
