.subscription {
  @apply flex flex-col gap-2xl;
  @apply sm:grid sm:grid-cols-2 xl:grid-cols-3;
  @apply mb-xl;
  @apply whitespace-nowrap text-ellipsis;
}

.buttonBar {
  @apply m-0 px-0 min-h-[4rem];
}

.planName {
  @apply text-accent;
}
