.checkbox {
  @apply p-0 h-full flex flex-col justify-center;
}

.header {
  @apply -mt-[0.25rem];
}

.checkboxCellContainer {
  @apply w-[2.5rem];
  /* Hide on small screens */
  @apply h-0 md:h-auto -my-md md:my-0;
}
