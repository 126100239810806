@keyframes flashError {
  0% {
    background-color: var(--alias-color-danger);
  }
  100% {
    background-color: inherit;
  }
}

.table {
  @apply w-full mb-xl;
}

.tableHeaderRow {
  @apply border-b border-t border-weak;
  @apply table-row;
}

.tableHeader {
  @apply py-md md:pl-md;
  @apply text-left text-accent text-xs sm:text-sm font-bold;
}
.tableHeader.sortable {
  @apply cursor-pointer;
}
.tableHeader > span {
  @apply flex;
}
.tableHeader.center > span {
  @apply justify-center;
}
.tableHeader.end > span {
  @apply justify-end;
}
.tableRow {
  @apply table-row;
  @apply py-0;
  @apply border-b border-weak;
}
.borderless .tableRow {
  @apply border-none;
}
.borderless .tableRow:last-of-type {
  @apply border-b border-weak border-solid;
}

.tableCell {
  @apply md:py-2 py-md md:pl-md;
  @apply table-cell;
  @apply text-left text-sm font-normal;
  @apply text;
}

.table > tbody .tableRow:first-of-type .tableCell {
  @apply md:pt-3;
}

.table > tbody .tableRow:last-of-type .tableCell {
  @apply md:pb-3;
}
.tableCell.center {
  @apply text-center;
}
.tableCell.end {
  @apply text-right;
  @apply text-end;
}
.textPrimary {
  @apply text-accent;
}
.textSecondary {
  @apply text-secondary;
}
.textTertiary {
  @apply text-tertiary;
}
.textquarternary {
  @apply text-quarternary;
}
.textDefault {
  @apply text;
}

.cellHeader {
  @apply uppercase;
  @apply mr-2;
  @apply text-left;
}

.sortIcon {
  @apply ml-2;
}
.sortIcon.inactive {
  @apply opacity-30;
}

.sortIcon > svg {
  fill: var(--alias-color-text-accent);
}

.noData {
  @apply flex justify-center items-center h-16 ;
  @apply text-sm text-secondary border-b border-weak;
}

.caption {
  @apply mb-4 text-gray-400;
}

@media only screen and (max-width: 767px) {
  .table.responsive .tableHeaderRow {
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
  }

  .table.responsive > tbody {
    @apply border-t border-weak;
  }

  .table.responsive .tableRow {
    @apply block;
    @apply py-2;
  }
  .table.responsive .tableRow:not(:last-child) {
    @apply border-b border-weak;
  }

  .table.responsive .tableCell {
    @apply flex justify-between;
    @apply py-2;
    @apply text-right;
  }
}
@media only screen and (min-width: 768px) {
  .table.responsive .cellHeader {
    @apply hidden;
  }
}

.rowError td, .rowError th {
  animation-name: flashError;
  animation-duration: 1.25s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
